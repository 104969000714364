<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-md-12">
						<h5>
							<strong>Reportes de Dirección</strong>
						</h5>
						<hr class="hrText" data-content="♦" />
					</div>
				</div>
				<a-tabs default-active-key="1">
					<a-tab-pane key="1" tab="Cortes" force-render>
						<cashflowView />
					</a-tab-pane>
					<a-tab-pane key="2" tab="Ventas del día" force-render>
						<div class="row">
							<div class="col-md-6">
								<b>Rango de Fecha </b>
								<a-icon type="reload" @click="onResetFilter('defaultDate')" :style="{ fontSize: '14px', color: 'red' }" />
								<a-range-picker style="width: 100%" @change="initModule" v-model="filters.defaultDate" :default-value="filters.defaultDate" :format="dateFormat" />
							</div>
							<div class="col-md-6 text-right">
								<a-button class="btn btn-success ml5" icon="reload" @click="initModule" />
							</div>
						</div>
						<div class="row pt20">
							<div class="col-md-3 text-center">
								<div class="card bg-blue-light border-0">
									<div class="card-body">
										<div class="text-white font-weight-bold">
											<div class="font-size-21">Total</div>
											<div class="font-size-18">{{ numeral(report.total).format('$0,0.00') }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-3 text-center">
								<div class="card bg-blue-light border-0">
									<div class="card-body">
										<div class="text-white font-weight-bold">
											<div class="font-size-21">Ganancia bruta</div>
											<div class="font-size-18">{{ numeral(earnings).format('$0,0.00') }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-3 text-center">
								<div class="card bg-blue-light border-0">
									<div class="card-body">
										<div class="text-white font-weight-bold">
											<div class="font-size-21">Costos de venta</div>
											<div class="font-size-18">{{ numeral(salesCosts).format('$0,0.00') }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-3 text-center">
								<div class="card bg-blue-light border-0">
									<div class="card-body">
										<div class="text-white font-weight-bold">
											<div class="font-size-21">Ganancia neta</div>
											<div class="font-size-18">{{ numeral(0).format('$0,0.00') }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-xs-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 text-center">
								<div class="card bg-success border-0">
									<div class="card-body">
										<div class="text-white font-weight-bold">
											<div class="font-size-21">Efectivo</div>
											<div class="font-size-18">{{ numeral(report.paymentData.payment_cash).format('$0,0.00') }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-xs-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 text-center">
								<div class="card bg-success border-0">
									<div class="card-body">
										<div class="text-white font-weight-bold">
											<div class="font-size-21">Terminal</div>
											<div class="font-size-18">{{ numeral(report.paymentData.payment_bank_terminal).format('$0,0.00') }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-xs-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 text-center">
								<div class="card bg-success border-0">
									<div class="card-body">
										<div class="text-white font-weight-bold">
											<div class="font-size-21">Transferencia</div>
											<div class="font-size-18">{{ numeral(report.paymentData.payment_transfer).format('$0,0.00') }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-2 text-center">
								<div class="card bg-success border-0">
									<div class="card-body">
										<div class="text-white font-weight-bold">
											<div class="font-size-21">PayPal</div>
											<div class="font-size-18">{{ numeral(report.paymentData.payment_paypal).format('$0,0.00') }}</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-2 text-center">
								<div class="card bg-success border-0">
									<div class="card-body">
										<div class="text-white font-weight-bold">
											<div class="font-size-21">MercadoPago</div>
											<div class="font-size-18">{{ numeral(report.paymentData.payment_mercadopago).format('$0,0.00') }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</a-tab-pane>
				</a-tabs>
			</div>
		</div>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import moment from 'moment-timezone'
import cashflowView from '@/views/cash-flow'

const dateFormat = 'YYYY-MM-DD'
moment.tz.setDefault('America/Mexico_City')

const theStruct = {
	paymentData: {
		payment_bank_terminal: 0,
		payment_cash: 0,
		payment_mercadopago: 0,
		payment_paypal: 0,
		payment_transfer: 0,
		payment_advance: 0,
	},
	totalTickets: 0,
	tickets: 0,
	total: 0,
	profit: 0,
	totalAreas: {
		boutique: {
			amount: 0,
			tickets: 0,
			payment_bank_terminal: 0,
			payment_cash: 0,
			payment_mercadopago: 0,
			payment_paypal: 0,
			payment_transfer: 0,
			payment_advance: 0,
		},
		service: {
			amount: 0,
			tickets: 0,
			payment_bank_terminal: 0,
			payment_cash: 0,
			payment_mercadopago: 0,
			payment_paypal: 0,
			payment_transfer: 0,
			payment_advance: 0,
		},
	},
}

// pozos - 11
// ismael - 1
// armando - 8

export default {
	name: 'leadershipView',
	components: {
		cashflowView,
	},
	computed: {
		...mapGetters('pos', ['filters', 'ticketList', 'earnings', 'salesCosts', 'spinnerLoaderLabel', 'spinnerLoader']),
		...mapGetters('user', ['user', 'isUserAdmin']),
	},
	data() {
		return {
			dateFormat,
			report: _.cloneDeep(theStruct),
			administradores: [1, 11, 12, 13, 8],
		}
	},
	beforeMount() {
		if (!this.administradores.includes(this.user.user_id)) {
			this.$router.replace('/dashboard')
		}
	},
	mounted() {
		this.initModule()
	},
	methods: {
		moment,
		numeral,
		initModule(date, dateString) {
			//
			let payload = {}

			if (!!dateString && dateString.length) {
				payload = {
					startDate: moment(dateString[0]),
					endDate: moment(dateString[1]),
				}
			} else {
				payload = {
					startDate: moment(this.filters.defaultDate[0]).format('YYYY-MM-DD'),
					endDate: moment(this.filters.defaultDate[1]).format('YYYY-MM-DD'),
				}
			}
			payload = {
				...payload,
				cashier: this.filters.cashier,
				paymentMethod: this.filters.paymentMethod,
			}

			this.$store.dispatch('pos/GET_TICKETS', payload)
		},
		onResetFilter(key) {
			this.$store.dispatch('pos/RESET_ONE_FILTER', key)
			this.initModule()
		},
	},
	watch: {
		ticketList(newData) {
			if (newData.length) {
				let report = _.cloneDeep(theStruct)

				let ticketList = _.cloneDeep(this.ticketList)
				report.totalTickets = this.ticketList.length

				ticketList.forEach((element) => {
					//
					let area = ''
					// Cajero Carlos o Armando
					if (element.cashier == 2 || element.cashier == 8) {
						area = 'service'
					} else {
						// Cajero Raziel
						area = 'boutique'
					}

					report.paymentData.payment_cash += element.payment_cash
					report.paymentData.payment_bank_terminal += element.payment_bank_terminal
					report.paymentData.payment_transfer += element.payment_transfer
					report.paymentData.payment_paypal += element.payment_paypal
					report.paymentData.payment_mercadopago += element.payment_mercadopago
					report.paymentData.payment_advance += element.payment_advance

					let total = element.payment_cash + element.payment_bank_terminal + element.payment_transfer + element.payment_paypal + element.payment_mercadopago

					report.total += total

					report.totalAreas[area].amount += total
					report.totalAreas[area].tickets++
					report.totalAreas[area].payment_bank_terminal += element.payment_bank_terminal
					report.totalAreas[area].payment_cash += element.payment_cash
					report.totalAreas[area].payment_mercadopago += element.payment_mercadopago
					report.totalAreas[area].payment_paypal += element.payment_paypal
					report.totalAreas[area].payment_transfer += element.payment_transfer
					report.totalAreas[area].payment_advance += element.payment_advance
				})

				this.report = _.cloneDeep(report)
			}
		},
	},
}
</script>
